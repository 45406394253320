import { useQuery } from '@apollo/client'
import { CategoryLabel } from 'components/CategoryLabel/CategoryLabel'
import { useLocation } from 'context/Location/LocationProvider'
import { motion } from 'framer-motion'
import { GET_USER } from 'gql/users'
import { getCoursePrice } from 'lib/currency'
import { formatPrice } from 'lib/utils'
import { useSession } from 'next-auth/client'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { Course } from 'types'
import { User } from 'types/user'
import ArrowIcon from '../../svg/arrow.svg'
import ClockIcon from '../../svg/clock.svg'
import DocumentIcon from '../../svg/document.svg'

// Common card dimensions in the site
export const CardDimensions = {
  Width: 600,
  Height: 425,
}

type CourseCardProps = {
  course: Course
}

const CourseCard = ({ course }: CourseCardProps) => {
  const { t } = useTranslation()
  const {
    title,
    timeframeWeeks,
    hoursPerWeek,
    isComingSoon,
    imageUrl,
    isFree,
    slug,
    courseProvider: provider,
    categories,
  } = course

  const [session] = useSession()

  const { country } = useLocation() // Use LocationContext to access country code

  // Load current profile
  const { data: userData } = useQuery<{ me: User }>(GET_USER, {
    skip: !session?.user,
  })
  const user = session?.user && userData?.me

  // Calculate the course price based on the stored country code (either from cookies or user)
  const coursePrice = getCoursePrice(course, user, country)

  const linkUrl = `/courses/${slug}`

  const imageVariants = {
    initial: {
      aspectRatio: `${CardDimensions.Width} / ${CardDimensions.Height}`,
    },
    hover: {
      aspectRatio: `${CardDimensions.Width} / ${CardDimensions.Height + 58}`,
    },
  }

  return (
    <motion.div
      className={`flex flex-col rounded-lg overflow-hidden h-full border-mischka ${course.isRecommended ? 'bg-dew shadow-hilight' : 'border-1 bg-white'} `}
      initial='initial'
      whileHover='hover'
    >
      <Link href={linkUrl}>
        <a>
          <motion.div
            className='relative flex-shrink-0 text-0 overflow-hidden'
            variants={imageVariants}
            transition={{ duration: 0.3 }}
          >
            {(isComingSoon || course.isRecommended) && (
              <div className='absolute top-4 right-4 flex gap-2'>
                {course.isRecommended && (
                  <div className='bg-turquoise items-center px-4 py-2 rounded-full z-10'>
                    <div className='leading-5 text-xs font-bold'>
                      {t('Featured')}
                    </div>
                  </div>
                )}
                {isComingSoon && (
                  <div className='bg-white items-center px-4 py-2 rounded-full z-10'>
                    <div className='leading-5 text-xs font-medium'>
                      {t('Coming Soon')}
                    </div>
                  </div>
                )}
              </div>
            )}
            {imageUrl && (
              <motion.div
                className='relative w-full h-full'
                variants={imageVariants}
                transition={{ duration: 0.3 }}
              >
                <Image
                  className='w-full object-cover'
                  src={imageUrl}
                  alt={title}
                  layout='fill'
                  objectFit='cover'
                  priority
                />
              </motion.div>
            )}
          </motion.div>
        </a>
      </Link>
      <div className='flex-1 p-6 flex flex-col justify-between'>
        <div className='flex flex-col gap-4 flex-1'>
          {categories.length > 0 && (
            <motion.div
              className='flex flex-wrap gap-2'
              variants={{
                initial: {
                  opacity: 1,
                  height: 'auto',
                },
                hover: {
                  opacity: 0,
                  height: 0,
                },
              }}
              transition={{ duration: 0.3 }}
            >
              {categories.map((category) => (
                <CategoryLabel key={category.id} category={category} />
              ))}
            </motion.div>
          )}
          <Link href={linkUrl}>
            <motion.a
              className='block'
              variants={{
                initial: {
                  marginTop: 0,
                },
                hover: {
                  marginTop: -16,
                },
              }}
              transition={{ duration: 0.3 }}
            >
              <p className='text-2xl font-medium font-heading'>{title}</p>
            </motion.a>
          </Link>
          {provider && (
            <p className='flex-auto text-sm font-medium'>{provider.title}</p>
          )}
          <motion.div
            className='flex'
            variants={{
              initial: {
                opacity: 1,
                height: 'auto',
              },
              hover: {
                opacity: 0,
                height: 0,
              },
            }}
            transition={{ duration: 0.3 }}
          >
            {timeframeWeeks && (
              <div className='flex px-0.5 h-5'>
                <ClockIcon className='text-purple-heart' />

                <div className='text-sm font-medium px-2 pt-0.5 truncate'>
                  {timeframeWeeks} {t('weeks')}
                </div>
              </div>
            )}
            {hoursPerWeek && (
              <div className='flex px-0.5 h-5'>
                <DocumentIcon className='text-purple-heart' />

                <div className='text-sm font-medium px-2 pt-0.5 truncate'>
                  {hoursPerWeek} hours/week
                </div>
              </div>
            )}
          </motion.div>
          <div className='flex justify-between items-center'>
            <div className='text-xl font-semibold'>
              {isFree
                ? 'Free'
                : formatPrice(coursePrice[0], coursePrice[1], true)}
            </div>
            <Link href={linkUrl} passHref>
              <a>
                <ArrowIcon className='text-tuna' />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default CourseCard
