import { Course, CourseType } from 'types/course'
import { Currency } from 'types/enrolment'
import { SocialEvent } from 'types/event'
import { User } from 'types/user'
import { countriesToSeeNZ, regionsToSeeNZ } from '../config/locale'
import { useLocation } from '../context/Location/LocationProvider'

/**
 * Pick currency this course should be priced on
 *
 * @param course
 * @param user
 * @param country
 * @param region
 * @return currency
 */
const getCourseCurrency = (
  course: Course,
  user?: User | null,
  country?: string,
  region?: string,
): Currency => {
  // Non-short courses need to respect domestic pricing rules
  if (course.type !== CourseType.SHORT_COURSE && user?.preferredCurrency) {
    return user.preferredCurrency
  }

  return getCountryCurrency(country, region)
}

/**
 * Pick default currency for a given country
 *
 * @param country
 * @param region
 * @return currency
 */
const getCountryCurrency = (country = '', region = ''): Currency => {
  switch (true) {
    case countriesToSeeNZ.includes(country):
    case regionsToSeeNZ.includes(region):
      return Currency.NZD
    case country === 'AU':
      return Currency.AUD
    default:
      return Currency.USD
  }
}

/**
 * Get course price and currency to show a user (hook version)
 *
 * @param course
 * @param user
 * @return price and currency
 */
export const useCoursePrice = (
  course?: Course,
  user?: User | null,
): [number | null, Currency | null] => {
  const { country, region } = useLocation()
  if (!course) {
    return [null, null]
  }

  return getCoursePrice(course, user, country, region)
}

/**
 * Get course price and currency to show a user (function version)
 *
 * @param course
 * @param country
 * @param region
 * @param user
 */
export const getCoursePrice = (
  course: Course,
  user?: User | null,
  country?: string,
  region?: string,
): [number, Currency] => {
  const { priceAU, priceNZ, priceUS } = course
  const prices = {
    NZD: priceNZ,
    AUD: priceAU,
    USD: priceUS,
  }
  const currency = getCourseCurrency(course, user, country, region)

  // Return price and currency
  return [prices[currency], currency]
}

/**
 * Get event price and currency to show a user (hook version)
 *
 * @param event
 * @return price and currency
 */
export const useEventPrice = (
  event?: SocialEvent,
): [number | null, Currency | null] => {
  const { country } = useLocation()
  if (!event) {
    return [null, null]
  }

  return getEventPrice(event, country)
}

/**
 * Get Event price and currency to show a user (function version)
 *
 * @param event
 * @param country
 */
export const getEventPrice = (
  event: SocialEvent,
  country?: string,
  region?: string,
): [number, Currency] => {
  const { priceAU, priceNZ, priceUS } = event
  const prices = {
    NZD: priceNZ,
    AUD: priceAU,
    USD: priceUS,
  }
  const currency = getCountryCurrency(country, region)

  // Return price and currency
  return [prices[currency], currency]
}
