import {
  Assessment,
  BlockType,
  CourseProvider,
  CourseStepComment,
  Programme,
  QuizQuestion,
  StepTag,
} from 'types'

export enum CourseType {
  FREE_COURSE = 'FREE_COURSE',
  SHORT_COURSE = 'SHORT_COURSE',
  MICROCREDENTIAL = 'MICROCREDENTIAL',
  POSTGRAD_CERTIFICATE = 'POSTGRAD_CERTIFICATE',
  MASTERS = 'MASTERS',
}

export enum CoursePaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  SOCIAL = 'SOCIAL',
  FEES_FREE = 'FEES_FREE',
  INVOICE = 'INVOICE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  STUDYLINK = 'STUDYLINK',
}

export type Category = {
  id: string | number
  title: string
  imageUrl: string
}

export type LearningOutcome = {
  id: string | number
  title: string
  imageUrl: string
}

export type Course = {
  id: string | number
  title: string
  slug: string
  courseProvider: CourseProvider
  programme: Programme
  categories: Category[]
  learningOutcomes: LearningOutcome[]
  period: 'week' | 'phase' | 'sprint' | 'session' | 'residency' | 'module'
  overview: string
  pageDetailsJson: BlockType
  ctaUrl: string
  timeframe: string // @deprecated
  timeframeWeeks: string
  hoursPerWeek: string
  credits: number
  isComingSoon: boolean
  isRecommended: boolean
  imageUrl: string
  pages: CoursePageLink[]
  startType: StartType
  priceNZ: number
  priceAU: number
  priceUS: number
  isFree: boolean
  type: CourseType
  publicIntakes: CourseIntake[]
  paymentMethods: CoursePaymentMethod[]
}

export interface CoursePageLink {
  id: string | number
  title: string
  slug: string
}

export interface CoursePage extends CoursePageLink {
  contentJson: BlockType
  course: Course
}

export enum FinalGrade {
  P = 'P',
  NYC = 'NYC',
}

export enum CourseIntakeTimeframe {
  NA = 'NA',
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
}

export type CourseIntake = {
  id: string | number
  title: string
  slug: string
  enrolUrl: string
  timeframe: CourseIntakeTimeframe
  courseEdition: PublicCourseEdition
  dateStart?: string // Note: date-only component
  dateEnd?: string // Note: date-only component
  isEnrolled: boolean
  canEnrol: boolean
}

export interface CourseEditionInterface {
  id: string | number
  title: string
  course: Course
  overview: string
  imageUrl: string
  slug: string
  isPublished: boolean
  canView: boolean
  durationInDays?: number
}

export interface PublicCourseEdition extends CourseEditionInterface {
  dateStart: string // Note: date-only component
  dateEnd: string // Note: date-only component
  courseSegments: PublicCourseSegment[]
}

export interface CourseEdition extends CourseEditionInterface {
  dateStart: string // Note: date and time the course started (exactly) component
  dateEnd: string // Note: date and time the course ends (exactly) component
  courseSegments: CourseSegment[]
  status: CourseStatus
  continueOnStep: CourseContinueStepReference
  completedPercent: number
  assessments: Assessment[]
  finalGrade: FinalGrade
}

export enum CourseStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface CourseSegmentInterface {
  id: string | number
  reference: string
  title: string
  beginAfter: string
  hasBegun: boolean
  isBreak: boolean
  canView: boolean
}

export interface PublicCourseSegment extends CourseSegmentInterface {
  courseSections: PublicCourseSection[]
}

export interface CourseSegment extends CourseSegmentInterface {
  courseSections: CourseSection[]
  courseEdition: CourseEdition
  completedPercent: number
  assessments: Assessment[]
}

export interface CourseSectionInterface {
  id: string | number
  title: string
  overview: string
  imageUrl: string
}

export interface PublicCourseSection extends CourseSectionInterface {}

export interface CourseSection extends CourseSectionInterface {
  courseSteps: CourseStep[]
  courseSegment: CourseSegment
}

export interface PublicCourseStep {
  id: string | number
  title: string
  reference: string
}

export interface CourseStep extends PublicCourseStep {
  nextStep?: CourseStepReference
  previousStep?: CourseStepReference
  courseSection: CourseSection
  status: StepStatus
  canComplete: boolean
  canUncomplete: boolean
  canView: boolean
  canComment: boolean
  tags?: StepTag[]
  comments: CourseStepComment[]
  __typename: string
}

export enum StepStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
  COMPLETED = 'COMPLETED',
  STARTED = 'STARTED',
}

export enum StartType {
  FIXED = 'Fixed',
  ROLLING = 'Rolling',
}

export interface CourseStepPage extends CourseStep {
  contentJson: BlockType
}

export interface CourseStepIframe extends CourseStep {
  contentJson: BlockType
  src: string
  height: string
}
export interface CourseStepQuiz extends CourseStep {
  introJson: BlockType
  summaryJson: BlockType
  quizQuestions: QuizQuestion[]
  nextQuestion: QuizQuestion
}

export interface CourseContinueStepReference {
  id: string | number
  reference: string
  title: string
  courseSection: CourseSectionReference
}

export interface CourseStepReference {
  id: string | number
  title: string
  reference: string
  courseSection: CourseSectionReference
}

export interface CourseSegmentReference {
  id: string | number
  title: string
  reference: string
  completedPercent: number
}

export interface CourseSectionReference {
  id: string | number
  title: string
  courseSegment: CourseSegmentReference
  completedPercent: number
}
