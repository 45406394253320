import HangarIcon from 'svg/door-hangar.svg'
import ControllerIcon from 'svg/game.svg'
import HealthIcon from 'svg/health.svg'
import MaoriIcon from 'svg/maori.svg'
import SoilIcon from 'svg/soil.svg'
import SuitcaseIcon from 'svg/suitcase.svg'
import SustainabilityIcon from 'svg/sustainability.svg'
import type { Category } from 'types/course'

const IconMap = [
  { title: 'Sustainability', icon: SustainabilityIcon },
  { title: 'Tech & Creativity', icon: ControllerIcon },
  { title: 'Entrepreneurship', icon: SuitcaseIcon },
  { title: 'Health & Wellbeing', icon: HealthIcon },
  { title: 'Food & Agritech', icon: SoilIcon },
  { title: 'Te Ao Māori', icon: MaoriIcon },
  { title: 'Tourism & Hospitality', icon: HangarIcon },
]

const getIcon = (category: Category) => {
  const icon = IconMap.find((x) => x.title === category.title)
  if (category.imageUrl) {
    return (
      <svg width='25' height='25'>
        <image href={category.imageUrl} width='25' height='25' />
      </svg>
    )
  }
  if (icon?.icon) {
    const IconComponent = icon.icon
    return <IconComponent />
  }
  return null
}

type CategoryLabelProps = {
  category: Category
  style?: 'menu' | 'tag'
}

export const CategoryLabel = ({
  category,
  style = 'tag',
}: CategoryLabelProps) => {
  if (style === 'menu') {
    return (
      <>
        <div>{getIcon(category)}</div>
        <div>{category.title}</div>
      </>
    )
  }

  return (
    <div className='px-3 py-[0.125rem] text-sm border font-medium rounded-md flex items-center gap-2'>
      {getIcon(category)}
      {category.title}
    </div>
  )
}
